import 'jquery';
var $ = require('jquery');
var jQueryBridget = require('jquery-bridget');
var Isotope = require('isotope-layout');
var Flickity = require('flickity');

require('flickity-imagesloaded');
require('flickity-fullscreen');

// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'

var waypoint = require('/node_modules/waypoints/lib/noframework.waypoints.js');

var mediaEl;

// make Isotope a jQuery plugin
jQueryBridget('isotope', Isotope, $);
// now you can use $().isotope()
// main.js
var imagesLoaded = require('imagesloaded');

// provide jQuery argument
imagesLoaded.makeJQueryPlugin($);


// now use .imagesLoaded() jQuery plugin

import TypeIt from "typeit";
import Headroom from "headroom.js";

import 'mediaelement';
//import 'mediaelement/build/mediaelementplayer.css'; // Import the player's CSS


window.jQuery = $;
window.$ = $;

// console.log($(window).height());




$(window).resize(function () {
    
    if (this.resizeTO) clearTimeout(this.resizeTO);
    this.resizeTO = setTimeout(function () {
        $(this).trigger('resizeEnd');
    }, 500);
});

/* Search logic */
document.addEventListener("DOMContentLoaded", function () { 
    let radioFilter = document.querySelectorAll('.radio-filter');
    let filter = document.querySelector('.filter');
    let btnPrefs = document.querySelector('.btn-prefs');
    let form = document.getElementById('sform');
    let searchBtn = document.querySelector('#seachFromFilter');
    const restoreFilter = document.getElementById('restoreFilter');
    let rand = document.getElementById('rand');
    if (restoreFilter) {
        restoreFilter.addEventListener('click', function () {
            resetFilter();
            //deactivateFilter();
            restoreFilter.classList.remove('vis');
        });

        radioFilter.forEach(function (radioButton) {
            // Add change event listener to each radio button
            radioButton.addEventListener('change', function (event) {
                // Log the value of the selected radio button
                console.log(event.target.value);
                var selectedValues = [];
                radioFilter.forEach(function (radioButton) {
                    if (radioButton.checked) {
                        selectedValues.push(radioButton.value);
                    }
                });
                var commaSeparatedString = selectedValues.join(',');
                console.log(commaSeparatedString);
                filter.value = commaSeparatedString;
                if (filter.value != '') {
                    activateFilter(btnPrefs);
                } else {
                    deactivateFilter(btnPrefs);
                }
            });
        });
        function resetFilter() {
            radioFilter.forEach(function (radioButton) {
                radioButton.checked = false;
            });
            filter.value = '';
        }
        function selectRadioByFilter() {
            radioFilter.forEach(function (radioButton) {
                //apply select
            });
        }
        searchBtn.addEventListener('click', function () {
            let random = getRandomInt(10000);
            rand.value = random;
            
            form.submit();
            console.log('submit');
        });
        function activateFilter(btnPrefs) {
            btnPrefs.classList.add('active');
            
            restoreFilter.classList.add('vis');
        }
        function deactivateFilter(btnPrefs) {
            btnPrefs.classList.remove('active');
            //alert('!!');
            restoreFilter.classList.remove('vis');
        }
        function extractFilterValuesFromUrl() {
            var filterValues = [];
            var urlParams = new URLSearchParams(window.location.search); // Get the query parameters from the current URL

            if (urlParams.has('filter')) { // Check if the filter parameter exists in the URL
                var filterParam = urlParams.get('filter'); // Get the value of the filter parameter
                filterValues = filterParam.split(','); // Split the comma-separated values into an array
            }

            return filterValues;
        }
        function getRandomInt(max) {
            return Math.floor(Math.random() * max);
        }
        // Usage example
        var filterArray = extractFilterValuesFromUrl();
        var isfiltered = false;
        filterArray.forEach(function (value) {
            var element = document.querySelector('[data-filter-id="' + value + '"]');
            if (element) {
                element.checked = true;
                isfiltered = true;
            }
            filter.value = filterArray.join(',');
        });
        if (isfiltered) {
            activateFilter(btnPrefs);
        } else {
            deactivateFilter(btnPrefs);
        }
        //console.log(filterArray); // Output: ["value1", "value2", "value3"]
    }

});




function removeAllclasses(ele, cName) {
    var allClasses = document.querySelectorAll(ele);
    for (var i = 0; i < allClasses.length; i++) {
        allClasses[i].classList.remove(cName);
    }
}
document.addEventListener("DOMContentLoaded", function () {
    
    var c_items = document.querySelectorAll('.v2-car-option-item .c-items');
    if (c_items) {
        for (var i = 0; i < c_items.length; i++) {
            c_items[i].addEventListener('click', function (e) {
                removeAllclasses('.v2-car-option-item .c-items','active');
                var active = e.currentTarget.id;
                e.currentTarget.classList.add('active');
                removeAllclasses('.c-image-items img', 'active');
                var active_image = document.querySelector('.' + active);
                if (active_image) {
                    active_image.classList.add('active');
                }
            });
        }    
    }

    let searchDropDown = document.getElementById('marketplace-dropdown');
    if (searchDropDown) {
        let dropdownItems = searchDropDown.querySelectorAll('.dropdown-item');
        console.log(dropdownItems);
        dropdownItems.forEach(element => {
            
            element.addEventListener('click', function () {
                let dropdownItem = element.getAttribute('data-id');
                location.href = '?cat=' + dropdownItem +'#marketplace-container';
            })
        });
        
    }
    
    
});

$(document).ready(function ($) {

    var _body = $('body');
    $('#site-header #button').on('click', function () {
        _body.toggleClass('menu-show');
    });


    $('#myvideo2').mediaelementplayer({
        success: function (mediaElement, originalNode, instance) {
            mediaEl = mediaElement;
            set_dimensions();
            register_video_button();
        }
    });

    var Mcarousel = document.querySelectorAll('.main-carousel');
    for (var i = 0; i < Mcarousel.length; i++) {
        
        var flkty = new Flickity(Mcarousel[i], {
            // options
            prevNextButtons: true,
            pageDots: false,
            on: {
                ready: function () { 
                    
                    var slides = this.slides.length;
                    for (var i = 0; i < slides; i++) {
                        var message = 'Stap ' + (i + 1) + ' <span class="blue">van ' + slides+'</span>';
                        var Ccel = this.cells[i].element;
                        var Ccel_ = Ccel.querySelector('.carousel-status');
                        if (Ccel_) {
                            Ccel_.innerHTML = message;    
                        }
                        
                    }
                }
            }
        });
        flkty.on('settle', function (index) {
            console.log(index+1);
        });
        // flkty.on('ready', function () {
        //     console.log(flkty.slides);
        //     console.log('flkty.slides');
        // });
        
    }
    

   


    function updateStatus(index, ele) {
        var carousel = ele.element;
        var Ecount = $('.carousel-cell', ele.element).length;
        var cellNumber = index + 1;
        $('.carousel-status', ele.element,).html(' Stap ' + cellNumber + ' <span class="blue">van ' + Ecount + '</span>');
        if (cellNumber == 1 && Ecount == 1) {
            $('.paginate--previous', ele.element).addClass('disable');
            $('.paginate--next', ele.element).addClass('disable');
        } else if (cellNumber == 1) {
            $('.paginate--previous', ele.element).addClass('disable');
            $('.paginate--next', ele.element).removeClass('disable');
        } else if (cellNumber == flkty.slides.length) {
            $('.paginate--next', ele.element).addClass('disable');
            $('.paginate--previous', ele.element).removeClass('disable');
        } else {
            $('.paginate--next', ele.element).removeClass('disable');
            $('.paginate--previous', ele.element).removeClass('disable');
        }
    }
    //updateStatus();
    //$carousel.on( 'change.flickity', updateStatus );

    // Flickity instance
    // var flkty = $carousel.data('flickity');
    // // elements
    // var $cellButtonGroup = $('.button-group--cells');
    // var $cellButtons = $cellButtonGroup.find('.button');

    // // update selected cellButtons
    // $carousel.on('select.flickity', function () {
    //     $cellButtons.filter('.is-selected')
    //         .removeClass('is-selected');
    //     $cellButtons.eq(flkty.selectedIndex)
    //         .addClass('is-selected');
    // });

    // // select cell on button click
    // $cellButtonGroup.on('click', '.button', function () {
    //     var index = $(this).index();
    //     $carousel.flickity('select', index);
    // });



    
});

var timer;
var playing;

var set_dimensions = function (timer) {
    setTimeout(function () {
        if (!timer) {
            timer = 0;
        }
        var width = $('.mejs__container').width();
        var height = (width / 640) * 360;
        $('.mejs__container').height(height);
    }, timer);

}

$(window).bind('resizeEnd', function () {
    set_dimensions();
});


var register_video_button = function () {
    
    $('.play-button').click(function () {
        $('body').toggleClass('playing');
        mediaEl.play();
        if (!$('body').hasClass('playing')) {
            mediaEl.pause();
        }
        //set_dimensions(1000);
    });
}

/* HEKTOR DEVELOP */
$(function ($) {

    $('.btn-wr-search').click(function () {
        $('body').addClass('show-search-modal');
        setTimeout(function () {
            $('form input').focus();
            $('.search-field').focus();
        }, 400)
    });

    $('.close-modal').click(function () {
        $('body').removeClass('show-search-modal');
    });



    // jQuery methods go here...
    //alert('test 123');
    $('.menu-item-has-children > a').on('click', function (e) {
        console.log(e.target);
        console.log($(this));
        $(this).next().toggleClass('open');
        //alert('test 1234');
        e.preventDefault();
    });
    // // simple as this!

    var myElement = document.querySelector(".menu-top-bar-wr");
    // construct an instance of Headroom, passing the element
    var headroom = new Headroom(myElement);
    // initialise
    headroom.init();

});




function waitForElement(elementPath, callBack) {
    window.setTimeout(function () {
        if (jQuery(elementPath).length) {
            callBack(elementPath, jQuery(elementPath));
        } else {
            waitForElement(elementPath, callBack);
        }
    }, 500)
}


//plugin scrollEnd
$(function () {
    var _body = $('body');
    var on = jQuery.fn.on, timer;
    jQuery.fn.on = function () {
        var args = Array.apply(null, arguments);
        var last = args[args.length - 1];

        if (isNaN(last) || (last === 1 && args.pop())) return on.apply(this, args);

        var delay = args.pop();
        var fn = args.pop();

        args.push(function () {
            var self = this, params = arguments;
            clearTimeout(timer);
            timer = setTimeout(function () {
                fn.apply(self, params);
            }, delay);
        });

        return on.apply(this, args);
    };



    // timeout loader
    var st = setTimeout(function () {
        $('body').addClass('loaded');

    }, 250);

    /* scollax */
    // Calling new Scrollax class
    //var parallax = new Scrollax();
    // Initialize Scrollax instance
    //parallax.init();




    /* isotope */
    // init Isotope
    var jQuerygrid;
    $('.grid').imagesLoaded(function () {
        jQuerygrid = $('.grid').isotope({
            itemSelector: '.grid-item',
            layoutMode: 'fitRows',
            masonry: {
                columnWidth: '.grid-sizer'
            },
            getSortData: {
                name: '.name',
                soort: '.soort',
                number: '.number parseInt',
                category: '[data-category]',
                weight: function (itemElem) {
                    var weight = $(itemElem).find('.weight').text();
                    return parseFloat(weight.replace(/[\(\)]/g, ''));
                }
            }
        });




        // filter functions
        var filterFns = {
            // show if number is greater than 50
            numberGreaterThan50: function () {
                var number = $(this).find('.number').text();
                return parseInt(number, 10) > 50;
            },
            // show if name ends with -ium
            ium: function () {
                var name = $(this).find('.name').text();
                return name.match(/iumjQuery/);
            }
        };

        // bind filter button click
        $('#filters').on('click', 'button', function () {
            var filterValue = $(this).attr('data-filter');
            console.log(filterValue);
            // use filterFn if matches value
            filterValue = filterFns[filterValue] || filterValue;
            console.log(filterValue);
            //jQuerygrid.isotope({ filter: '.onderwijs ,.overheid' });
            jQuerygrid.isotope({ filter: filterValue });
        });

        // bind sort button click
        $('#sorts').on('click', 'button', function () {
            var sortByValue = $(this).attr('data-sort-by');
            jQuerygrid.isotope({ sortBy: sortByValue });
        });

        // change is-checked class on buttons
        $('.button-group').each(function (i, buttonGroup) {
            var jQuerybuttonGroup = $(buttonGroup);
            jQuerybuttonGroup.on('click', 'button', function () {
                jQuerybuttonGroup.find('.is-checked').removeClass('is-checked');
                $(this).addClass('is-checked');
                var cat = $(this).data('cat');

                $('.case-ref-item').addClass('hide');
                $('.' + cat).removeClass('hide');
                $('html, body').animate({
                    scrollTop: $("#filters").offset().top - 150
                }, 300);
            });
        });
    });



    // https://codepen.io/desandro/pen/wfaGu




    //waypooint for feature
    var wps = document.querySelectorAll('.feature');
    for (var i = 0; i < wps.length; i++) {
        const waypoint = new Waypoint({
            element: wps[i],
            handler: function (direction) {
                
                if (direction == 'down') {
                    //$(this[0, 'element']).addClass('show-feature');
                    this.element.classList.add('show-feature');
                    
                    //$(this.element).addClass('show-feature');

                    
                } else {
                    //_body.removeClass('show-feature');
                    //$(this[0, 'element']).removeClass('show-feature');
                    this.element.classList.remove('show-feature');
                    
                }
            },
            offset: '75%'

        });
    }

    //waypooint for feature
    var wps = document.querySelectorAll('.feature');
    for (var i = 0; i < wps.length; i++) {
        const waypoint = new Waypoint({
            element: wps[i],
            handler: function (direction) {
                if (direction == 'down') {
                    //$(this[0, 'element']).addClass('show-feature');
                    this.element.classList.add('show-feature');
                    //console.log(this.element);
                    //$(this.element).addClass('show-feature');

                    //console.log('down');
                } else {
                    //_body.removeClass('show-feature');
                    //$(this[0, 'element']).removeClass('show-feature');
                    //console.log('up');
                }
            },
            offset: '75%'

        });
    }

    //waypooint for feature contact
    var wps1 = document.querySelectorAll('.feature-contact');
    for (var i = 0; i < wps1.length; i++) {
        const waypoint = new Waypoint({
            element: wps1[i],
            handler: function (direction) {
                if (direction == 'down') {
                    //$(this[0, 'element']).addClass('show-feature');
                    this.element.classList.add('show-feature');
                    //$(this.element).addClass('show-feature');
                } else {
                    //_body.removeClass('show-feature');
                    //$(this[0, 'element']).removeClass('show-feature');
                }
            },
            offset: '75%'

        });
    }


    //waypooint for .feature-icon-list
    var wps2 = document.querySelectorAll('.feature-icon-list');
    for (var i = 0; i < wps2.length; i++) {
        const waypoint = new Waypoint({
            element: wps2[i],
            handler: function (direction) {
                if (direction == 'down') {
                    //$(this[0, 'element']).addClass('show-feature');
                    this.element.classList.add('show-feature');
                    
                    //$(this.element).addClass('show-feature');

                    
                } else {
                    //_body.removeClass('show-feature');
                    //$(this[0, 'element']).removeClass('show-feature');
                    this.element.classList.remove('show-feature');
                }
            },
            offset: '75%'

        });
    }

    

    // var waypoints = $('.feature,.feature-contact,.feature-icon-list').waypoint(function (direction) {
    //     if (direction == 'down') {
    //         $(this[0, 'element']).addClass('show-feature');
    //         //$(this.element).addClass('show-feature');

    //         console.log('down');
    //     } else {
    //         //_body.removeClass('show-feature');
    //         $(this[0, 'element']).removeClass('show-feature');
    //         console.log('up');
    //     }
    // }, {
    //     offset: '75%'
    // })
});
    





// scroll breaker
$('body,html').bind('scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove', function (e) {
    if (e.which > 0 || e.type == "mousedown" || e.type == "mousewheel" || e.type == "touchmove") {
        $("body,html").stop();
    }
});


window.addEventListener('DOMContentLoaded', (event) => {
    var ishome = document.body.classList.contains('home');
    if (ishome) {
        var typeitPause = 600;

        new TypeIt('.header-home h1 #extra', {
            speed: 50,
            autoStart: true,
            cursorChar: '|',
            startDelay: 0,
            nextStringDelay: 0,
            lifeLike: true,
            breakLines: true
        }).go();
    }
   
});

const select = document.querySelector('.jd-lng-wr select');
select.addEventListener('change', (e) => {
    document.location.href = select.value;
});

$(function () {
    // dropdown
    $('.item-has-children a').click(function (e) {
        var this_ = $(this);
        $(this_).toggleClass('open');
        console.log('open');
        e.preventDefault();
    });
});

    

